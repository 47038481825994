import { createSlice } from '@reduxjs/toolkit';
import { checkIsSense } from 'components/devices/IwSense/utils/senseDevices';

const initialState = {
    allSenseParameters: [],
    devices: {},
    parametersArray: [],
};

const parametersSlice = createSlice({
    name: 'parameters',
    initialState,
    reducers: {
        setAllSenseParameters: (state, action) => {
            state.allSenseParameters = action.payload;
        },
        setInitialState: (state) => {
            state.parametersArray = [];
            state.devices = {}
        },
        setId: (state, action) => {
            const { deviceId } = action.payload;

            if (!state.devices[deviceId]) {
                state.devices[deviceId] = { id: deviceId };
            }
            state.devices[deviceId].id = deviceId;
        },
        setError: (state, action) => {
            const { deviceId, payload } = action.payload;

            if (!state.devices[deviceId]) {
                state.devices[deviceId] = { id: deviceId };
            }
            state.devices[deviceId].error = payload;
        },
        resetState: () => {
            return initialState;
        },
        setDevice: (state, action) => {
            const { deviceId, device } = action.payload;
            if (Object.keys(device).length === 0) {
                state.devices = {};
            } else {
                state.devices[deviceId] = { ...device };
            }
        },

        setParametersArray: (state, action) => {
            const { newParam, isParamTrend } = action.payload;

            // If the array of new parameters is empty, reset parametersArray
            if (Array.isArray(newParam) && newParam.length === 0) {
                state.parametersArray = newParam;
                return;
            }

            // Check if the device is a "sense"
            const isSenseDevice = checkIsSense(newParam.device.model);

            let newArray;

            if (isSenseDevice) {
                // Mapping to update or keep existing parameters
                newArray = state.parametersArray.map(param => {
                    // If the parameter has "ma", check if there is a parameter with the same "ma"
                    if (Object.prototype.hasOwnProperty.call(newParam, 'ma')) {
                        if (param.device.id === newParam.device.id && param.ma === newParam.ma) {
                            return newParam; // Update the existing parameter
                        }
                    } else if (Object.prototype.hasOwnProperty.call(newParam, 'channel') && Object.prototype.hasOwnProperty.call(newParam, 'measure')) {
                        // If the parameter has "channel" and "measure", check if there is a parameter with the same "channel" and "measure"
                        if (param.device.id === newParam.device.id && param.channel === newParam.channel && param.measure === newParam.measure) {
                            return newParam; // Update the existing parameter
                        }
                    } else if (param.device.id === newParam.device.id && param.title === newParam.title) {
                        // Check if the title is the same to avoid "Temperature" overwriting "Flow Rate"
                        if (param.title === 'Flow Rate' && newParam.title === 'Temperature') {
                            return param; // Do not overwrite Flow Rate with Temperature
                        }
                        return newParam; // Otherwise, update the existing parameter
                    }
                    return param; // Keep the existing parameter
                });

                // Add the parameter if it does not already exist
                if (Object.prototype.hasOwnProperty.call(newParam, 'ma')) {
                    if (!newArray.some(param => param.device.id === newParam.device.id && param.ma === newParam.ma)) {
                        newArray.push(newParam);
                    }
                } else if (Object.prototype.hasOwnProperty.call(newParam, 'channel') && Object.prototype.hasOwnProperty.call(newParam, 'measure')) {
                    if (!newArray.some(param => param.device.id === newParam.device.id && param.channel === newParam.channel && param.measure === newParam.measure)) {
                        newArray.push(newParam);
                    }
                } else {
                    // If the parameter does not have "ma", "channel" or "measure", based on title
                    if (!newArray.some(param => param.device.id === newParam.device.id && param.title === newParam.title)) {
                        newArray.push(newParam);
                    }
                }
            } else {
                // Mapping to update or keep existing parameters
                newArray = state.parametersArray.map(param => {
                    if (param.device.id === newParam.device.id && param.title === newParam.title) {
                        return newParam; // Update the existing parameter
                    }
                    return param; // Keep the existing parameter
                });

                // Special condition for non-sense devices
                if (!(newParam.device.position === 2 && (newParam.title === 'Temperature' || newParam.title === 'Flow Rate') && !isParamTrend)) {
                    if (!newArray.some(param => param.device.id === newParam.device.id && param.title === newParam.title)) {
                        newArray.push(newParam);
                    }
                }
            }

            // Final sorting by device ID
            newArray.sort((a, b) => a.device.id - b.device.id);
            // Update the state
            return { ...state, parametersArray: newArray };
        },

    }
});

export const {
    setAllSenseParameters,
    setParameter,
    setId,
    setError,
    resetState,
    setDevice,
    setParametersArray,
    setInitialState
} = parametersSlice.actions;

export default parametersSlice;
