import { getDeviceStatus } from 'util/status';
import { range_options } from '../maps/channel_map';

// Method used to check if a given model is a sense device
export const checkIsSense = (model) => {
    return model.includes('SENSE') || model.includes('US');
}
/**
 * Checks a model string for a numeric component between 1 and 3
 * and determines its ending part (PR, CD, CL) if applicable.
 * @param {string} model - The input model string.
 * @returns {string|null} - A combined string of the numeric component and ending part, or just the numeric component if 
 * greater than or equal to 3,or null if no numeric component is found.
 */
export const checkSenseModel = (model) => {
    let modelResult = {};

    // Extract the number between 1, 2, and 3 from the model string
    const matchNumber = model.match(/[1-3]/);
    const number = matchNumber ? parseInt(matchNumber[0]) : null;

    if (number) {
        modelResult['number'] = number;

        // get the starting index based on the model prefix length
        const modelPrefixLength = model.startsWith('SENSE') ? 6 : model.startsWith('US') ? 3 : 0;

        modelResult['channels'] = Array.from({ length: number }, (_, i) => {
            const value = i + 1;
            const startIndex = modelPrefixLength + i * 2;
            const endIndex = startIndex + 2;
            const type = model.substring(startIndex, endIndex);

            return { value, type };
        });
    }
    return modelResult;
};

/**
 * Generates a new settings configuration based on the given parameters.
 * @param {number} channel - The current channel number.
 * @param {boolean} isAux - Indicates if the channel is auxiliary.
 * @param {boolean} isOut - Indicates if the channel is output.
 * @param {boolean} isMa - Indicates if the channel is main.
 * @param {function} channelNameMapping - A function to map channel index to name.
 * @param {function} handleItemClick - A function to handle item click events.
 * @param {string} model - The model of the device.
 * @returns {Array} An array of settings objects.
 */
export const generateNewSettings = (channel, isAux, isOut, isMa, channelNameMapping, handleItemClick, model) => {
    const { outElements, auxElements, maElements } = calculateElementCounts(model);

    let elementsCount = 0;
    if (isOut) elementsCount = outElements.elementsCount;
    else if (isAux) elementsCount = auxElements.elementsCount;
    else if (isMa) elementsCount = maElements.elementsCount;
    else elementsCount = channel;

    // Generate an array of settings objects based on the determined number of elements
    return Array.from({ length: elementsCount }, (_, index) => {
        return {
            icon: 'build',
            name: channelNameMapping(index),
            state: 'blue',
            rightIcon: 'next',
            click: () => {
                if (isAux) {
                    const correctedIndex = 4 + index;
                    handleItemClick(correctedIndex);
                } else handleItemClick(index);
            }
        };
    });
};

/**
 * Checks the device configuration for temperature settings and returns the appropriate value.
 * It first checks for a MODET setting, which is used for  devices that support temperature control. If MODET is present and greater than 0, it returns the value of MODET. If MODET is not present or is 0, it then checks for a TCAL setting, which is used for devices that support calibration temperature. If TCAL is present and greater than 0, it returns the value of TCAL. If neither MODET nor TCAL is present or is greater than 0, it returns null, indicating that no temperature setting is enabled.
 * @param {Object} values - The values object containing the current device data.
 * @returns {number|null} - The temperature setting value if found, otherwise null.
 */
export const checkTemperature = (values) => {
    const configuration = values.currentDeviceData.configuration;
    if (configuration?.MODET && configuration?.MODET > 0) {
        // Return the MODET value if it's present and greater than 0
        return configuration.MODET;
    } else if (configuration?.TCAL && configuration?.TCAL > 0) {
        // Return the TCAL value if it's present and greater than 0
        return configuration.TCAL;
    } else {
        // Return null if no temperature setting is found
        return null;
    }
};

/**
 * This function checks the configuration of a device to determine the status of a specific element. It considers both the meter status (validity) and the enabled status of the element, returning an object with these two properties.
 * @param {number} index - The index of the element to check.
 * @param {Object} configuration - The configuration object of the device.
 * @param {boolean} isMa - Indicates if the element is a main element.
 * @param {boolean} isAux - Indicates if the element is an auxiliary element.
 * @returns {Object} - An object with 'meter' and 'enabled' properties.
 */
function getElementStatus(index, configuration, isMa) {
    const meterProperty = isMa ? `METERmA${index}` : `METER${index}`;
    const enableProperty = isMa ? `METERmA${index}` : `ENABLED${index}`;
    const relayexProperty = `RELAYEX${index}`;
    const relayex = +configuration[relayexProperty] == 1;
    const enabled = isMa ? +configuration[enableProperty] !== 0 : +configuration[enableProperty] == 1;
    const meter = +configuration[meterProperty] !== 0;
    return { meter, enabled, relayex };
}

/**
 * This function creates an array of settings objects for a given type of element (e.g., 'out', 'mA', 'mis').
 *  The function uses the `getElementStatus` function to determine the meter active and enabled statuses based on the element's index and the device's configuration.
 * 
 * @param {string} type - The type of the element ('out', 'aux', or 'mA').
 * @param {number} elementsCount - The number of elements to generate settings for.
 * @param {Object} configuration - The configuration object of the device.
 * @param {boolean} [isMa=false] - Indicates if the element is a main element.
 * @param {boolean} [isAux=false] - Indicates if the element is an auxiliary element.
 * @returns {Array} - An array of settings objects for the specified type of element.
 * outElements, configuration, statusObject, model, heartBeat
 */
function generateElementSettings(elements, configuration, statusObject, model, heartBeat, isMa = false, isMis = false, isAux = false) {
    const { type, elementsCount } = elements;

    return Array.from({ length: elementsCount }, (_, index) => {
        // Calculate the correct index based on whether it's an auxiliary element (isAux)
        const correctIndex = isAux ? index + 5 : index + 1;

        // Determine the appropriate key to check the status, based on whether it's mA, Mis, or standard
        const alarmKeyPrefix = isMa ? 'ALARMmA' : isMis ? 'ALARMMIS' : 'ALARM';
        const alarmKey = `${alarmKeyPrefix}${correctIndex}`;
        const statusToCheck = statusObject[alarmKey];

        // Get the status of the element (meter, enabled status, relayex status)
        const { meter, enabled, relayex } = getElementStatus(correctIndex, configuration, isMa);

        // Build the element's name based on its type (out, mis, or other types)
        const elementName = type === 'out'
            ? `RELAY${index + 1}`
            : type === 'mis'
                ? `MIS${index + 1}`
                : `${type.charAt(0).toUpperCase()}${type.slice(1)}${index + 1}`;

        // Return the constructed element object with relevant data
        return {
            id: index,
            name: elementName,
            meterActive: isMis ? true : meter, // Set meterActive based on meter or relayex status
            enabled: isMis ? true : enabled, // Set enabled status based on enabled or relayex
            ...type === 'out' ? { relayex } : {},
            type: type, // Element type (out, mis, etc.)
            status: getDeviceStatus(configuration, statusToCheck, model, heartBeat) // Calculate the device status
        };
    });
}

/**
 *  This function calculates the number of elements for 'out', 'mA' and 'mis' types based on the device model and generates settings for each type. It uses the `getElementStatus` function to determine the status of each element.
 * @param {string} model - The model of the device.
 * @param {Object} configuration - The configuration object of the device.
 * @returns {Object} - An object containing arrays of settings for 'outs', 'mas', and 'mises'.
 */
export const generateSettingsByModel = (device) => {
    const { model, configuration, statusObject, heartBeat } = device;
    const { outElements, maElements, misElements } = calculateElementCounts(model);

    const outSettings = generateElementSettings(outElements, configuration, statusObject, model, heartBeat);
    const maSettings = generateElementSettings(maElements, configuration, statusObject, model, heartBeat, true);
    const misSettings = generateElementSettings(misElements, configuration, statusObject, model, heartBeat, false, true);
    return {
        outs: outSettings,
        mas: maSettings,
        mises: misSettings
    };
};
/**
 *This function determines the number of elements for each type ('out', 'aux', 'mA') based on the device model and  generates settings for each type. It uses the `getElementStatus` function to determine the status of each element.
 * 
 * @param {string} model - The model of the device.
 * @param {Object} configuration - The configuration object of the device.
 * @returns {Object} An object containing arrays of settings for 'outs', 'auxs', and 'mas'.
 */
export const calculateElementCounts = (model) => {
    const match = model.match(/(?:SENSE|US)(\d)/);
    const modelNumber = parseInt(match[1]);

    const outElementsCount = modelNumber === 1 ? 2 : (modelNumber === 2 ? 3 : (modelNumber === 3 ? 4 : 0));
    const auxElementsCount = modelNumber;
    const maElementsCount = modelNumber === 1 ? 2 : (modelNumber === 2 ? 3 : (modelNumber === 3 ? 3 : 0));
    const misElementsCount = modelNumber === 1 ? 1 : (modelNumber === 2 ? 2 : (modelNumber === 3 ? 3 : 0));

    return {
        senseOutputs: [
            { type: 'out', elementsCount: outElementsCount },
            { type: 'aux', elementsCount: auxElementsCount },
            { type: 'mA', elementsCount: maElementsCount },
            { type: 'mis', elementsCount: misElementsCount },
        ],
        outElements: { type: 'out', elementsCount: outElementsCount },
        auxElements: { type: 'aux', elementsCount: auxElementsCount },
        maElements: { type: 'mA', elementsCount: maElementsCount },
        misElements: { type: 'mis', elementsCount: misElementsCount },
    };
};
/**
 * Finds an object within an array of objects based on a numeric ID.
 * The ID is extracted from the key of each object, which is expected to end with a number.
 * The function returns the first object that matches the given ID.
 * @param {Array} arrayOfObjects - The array of objects to search through.
 * @param {number} id - The numeric ID to match against the object keys.
 * @returns {Object|undefined} - The found object or undefined if no match is found.
 */
export const findObjectById = (arrayOfObjects, id, ma = false) => {
    // Check if arrayOfObejcts is valid
    if (!Array.isArray(arrayOfObjects) || arrayOfObjects.length === 0) {
        return null;
    }

    const foundObject = arrayOfObjects.find(obj => {
        // Get the first (and presumably only) key of the current object
        const key = Object.keys(obj)[0];
        // Extract the numeric part from the end of the key using a regular expression
        const number = parseInt(key.match(/\d+$/)[0], 10);
        // Check if the key ends with 'mA{number}' if ma is true
        const isMaKey = ma ? key.match(/mA\d+$/) : false;
        // Return true if the numeric part matches the id and the key ends with 'mA{number}' if ma is true
        return number == id && (!ma || isMaKey);
    });
    // Return the value of the found object
    return foundObject ? Object.values(foundObject)[0] : null;
}

/**
 * This function uses a regular expression to find and extract a numeric value representing a channel number from the input text. It's useful for parsing strings that include channel numbers, such as "Out 3" or "Channel 5".
 * 
 * @param {string} text - The input text string from which to extract the channel number.
 * @returns {number|null} The extracted channel number as an integer, or null if no number is found.
 */
export const getChannelNumber = (text) => {
    const regex = /\d+/;
    const match = text.match(regex);

    if (match) {
        return parseInt(match[0], 10);
    } else {
        return null;
    }
}

// Function to check MODEM values depending on the model of Sense
export const checkModemValues = (device, isSense) => {
    if (!device?.configuration) return null;

    // If not a Sense device, check standard MODEM value
    if (!isSense) {
        return device.configuration.MODEM?.toString().charAt(0) || null;
    }

    // For Sense devices, extract the number from the model and check MODEM1, MODEM2, etc.
    const model = device.model?.toString() || '';
    const senseModelNumber = model.match(/\d+/)?.[0]; // Extract the first number in the model

    if (!senseModelNumber) return null;

    const numberOfModems = parseInt(senseModelNumber, 10); // Convert to integer

    // Collect modem values dynamically based on the number of modems
    return Array.from({ length: numberOfModems }, (_, i) =>
        device.configuration[`MODEM${i + 1}`]?.toString().charAt(0) || null
    );
};

/**
 * Retrieves the label for the modem variant based on the MOMEM value from device configuration.
 *
 * @param {object} device - The device object containing the configuration.
 * @param {number} channel - The channel number to retrieve the MODEM${channel} variant.
 * @returns {string} The label that must be rendered.
 */
export const getModemVariant = (device, channel) => {
    const modemValue = device.configuration[`MODEM${channel}`].split('.')[1];
    const modemVariant = range_options.find(el => el.type == modemValue);

    return modemVariant.label;
};
