import React, { useState, useEffect, lazy } from 'react';
import { Routes, Route } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

// Ionic
import { setupIonicReact } from '@ionic/react';

// Capacitor
import { Capacitor } from '@capacitor/core';

// Components
import FullPageLoader from 'components/common/FullPageLoader/FullPageLoader';
import Layout from './components/layout/Layout/Layout';
import OptionModal from 'components/base/OptionModal/OptionModal';
import ProtectedRoute from 'components/hoc/ProtectedRoute';
import SuspenseComponent from 'components/hoc/SuspenseComponent';

// Hook
import { useAppVersion } from 'hooks/app-version-hook';
import { usePushNotifications } from 'hooks/push-notifications-hook';

// Styles
import './styles/styles.scss';

// translations
import { useTranslation } from 'react-i18next';

// Util
import { routes } from 'util/routes';

// Route lazy views
const Home = lazy(() => import('view/Home/Home'));
const Page404 = lazy(() => import('view/Page404/Page404'));
const Notifications = lazy(() => import('view/Notifications/Notifications'));
const PlantRoutes = lazy(() => import('routes/PlantRoutes'));
const PeopleRoutes = lazy(() => import('routes/PeopleRoutes'));
const LoginRoutes = lazy(() => import('routes/LoginRoutes'));

/**
 * The main App component, which renders the Layout and Routes.
 * It also sets up the platform and handles the app version.
 * @returns {ReactElement} The App component.
 */
function App() {
  setupIonicReact();

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [platform, setPlatform] = useState('web');
  const { cognitoToken } = useSelector((state) => state.login);

  const { openModal, handleUpdateApp } = useAppVersion();
  usePushNotifications(platform);

  // Get platform name
  useEffect(() => {
    const platformName = Capacitor.getPlatform();
    if (platformName === 'ios' || platformName === 'android') {
      setPlatform('mobile');
    } else {
      setPlatform('web');
    }
  }, [dispatch]);

  return (
    <Layout>
      {platform === 'mobile' && <OptionModal
        isOpen={openModal}
        subtitle={t('app.update_subtitle')}
        title={t('app.update_title')}
        btnText={t('actions.go_to_store')}
        btnFn={handleUpdateApp}
        isCancelBtn
      />}
      <Routes>
        <Route element={<ProtectedRoute variable={cognitoToken} to={routes.login} />}>
          <Route path={routes.home} element={<SuspenseComponent Component={Home} loader={<FullPageLoader />} />} exact />
          <Route path={routes.notifications} element={<SuspenseComponent Component={Notifications} loader={<FullPageLoader />} />} exact />
          <Route path={routes.plants + '/*'} element={<SuspenseComponent Component={PlantRoutes} loader={<FullPageLoader />} />} exact />
          <Route path={routes.people + '/*'} element={<SuspenseComponent Component={PeopleRoutes} loader={<FullPageLoader />} />} exact />
        </Route>
        <Route path={`${routes.login}/*`} element={<SuspenseComponent Component={LoginRoutes} loader={<FullPageLoader />} />} />
        <Route path='*' element={<SuspenseComponent Component={Page404} loader={<FullPageLoader />} />} />
      </Routes>
    </Layout>
  );
}

export default App;