export const models = {
    '151E': { modelFamily: 'DOSE' },
    '151C': { modelFamily: 'DOSE' },
    '151M': { modelFamily: 'DOSE' },
    '151P': { modelFamily: 'DOSE' },
    'DOCK': { modelFamily: 'DOCK' },
    'IWM2D': { modelFamily: 'DOSE' },
    'IWM2P': { modelFamily: 'POOL' },
    'IWM3D': { modelFamily: 'DOSE' },
    'IWM3P': { modelFamily: 'POOL' },
    'MATIC': { modelFamily: 'MATIC' },
    'MULTIDOSE': { modelFamily: 'DOSE' },
    'MULTIPOOL': { modelFamily: 'POOL' },
    'POOL': { modelFamily: 'POOL' },
    'POOLP': { modelFamily: 'POOL' },
    'SENSE1CD': { modelFamily: 'SENSE' },
    'SENSE1CL': { modelFamily: 'SENSE' },
    'SENSE1PR': { modelFamily: 'SENSE' },
    'SENSE2PRCD': { modelFamily: 'SENSE' },
    'SENSE2PRCL': { modelFamily: 'SENSE' },
    'SENSE2PRPR': { modelFamily: 'SENSE' },
    'SENSE3PRPRCL': { modelFamily: 'SENSE' },
    'UDC': { modelFamily: 'DOSE' },
    'UDM': { modelFamily: 'DOSE' },
    'UDP': { modelFamily: 'DOSE' },
    'ULTRAM': { modelFamily: 'ULTRAPUMP' },
    'ULTRAP': { modelFamily: 'ULTRAPUMP' },
    'UPM': { modelFamily: 'ULTRAPUMP' },
    'UPP': { modelFamily: 'ULTRAPUMP' },
    'US1CD': { modelFamily: 'SENSE' },
    'US1CL': { modelFamily: 'SENSE' },
    'US1PR': { modelFamily: 'SENSE' },
    'US2PRCD': { modelFamily: 'SENSE' },
    'US2PRCL': { modelFamily: 'SENSE' },
    'US2PRPR': { modelFamily: 'SENSE' },
    'US3PRPRCL': { modelFamily: 'SENSE' },
};

export const deviceDefaultValues = {
    'cl': {
        value: {
            setpoint_0: 0.8,
            setpoint_1: 1.2,
            maxFrequency: 120,
        }
    },
    'ph': {
        value: {
            setpoint_0: 7.2,
            setpoint_1: 7.6,
            maxFrequency: 100,
        }
    },
    'orp': {
        value: {
            setpoint_0: 650,
            setpoint_1: 750,
            maxFrequency: 100,
        }
    },
}

